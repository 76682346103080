// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("../node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("../node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-js": () => import("../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-landing-js": () => import("../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-thanks-js": () => import("../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-validate-js": () => import("../src/pages/validate.js" /* webpackChunkName: "component---src-pages-validate-js" */),
  "component---src-pages-welcome-js": () => import("../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

